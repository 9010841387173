// 回显
/*
* value: 值
* data: 字典数据
* prop: 定义取值，默认：{ value: dictValue, label: dictLabel }
* */
export function echoLabel(value, data, prop){
    var resultLabel=''
    if(value && data){
        if(prop){
            data.forEach(e=>{
                if(e[prop.value]==value){
                    resultLabel=e[prop.label]
                }
            })
        }else{
            data.forEach(e=>{
                if(e.dictValue==value){
                    resultLabel=e.dictLabel
                }
            })
        }
    }
    return resultLabel
}

// 跳转外部链接
export function goExternalPage(url){
    window.open(url, '_blank')
}

/**重写elemen-ui的message，防止出现同一时间弹出多个相同信息的message弹框 */
import { Message } from 'element-ui';
const resetMessage =(options) => {
  let doms = document.getElementsByClassName('el-message');
  let canShow = true;
  for( let i=0; i<doms.length; i++){
    if(options.message == doms[i].getElementsByClassName('el-message__content')[0].innerHTML){
      canShow = false;
    }
  }
  if(doms.length === 0 || canShow){
    Message(options)
  }
};
['error','success','info','warning'].forEach(type => {
  resetMessage[type] = options => {
    if(typeof options === 'string') {
      options = {
        message:options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
export const message = resetMessage

