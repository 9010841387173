// 网关： business
import request from '@/utils/request'
// 获取申报
export function getBidItemPage(params) {
  return request({
    url: `/cbd-portal/policy/getBidItemPage`,
    method: 'get',
    params
  })
}
export function getPolicyApplyPage(params) {
  return request({
    url: `/cbd-portal/portal/policyNew/getPolicyApplyPage`,
    method: 'get',
    params
  })
}

export function getPage(params) {
    return request({
      url: `/cbd-portal/policy/getPage`,
      method: 'get',
      params
    })
}

export function getDetail(params) {
    return request({
      url: `/cbd-portal/policy/getById/`+params,
      method: 'get'
    })
}

export function getItemDetail(params) {
    return request({
      url: `/cbd-portal/policy/item/getById/`+params,
      method: 'get'
    })
}

export function getDicts(params) {
  return request({
    url: `/user/component/dict/data/dictType/`+params,
    method: 'get'
  })
}
// 获取文件token
export function workOrderFileToken(params) {
  return request({
    url: `/file/fileToken/byBusiness/${params}`,
    method: "get",
  });
}
// 我的订阅-按类型汇总列表
export function getSelectList(params) {
  return request({
    url: `/cbd-portal/account/profile/subscribe/policy/getSelectList`,
    method: "get",
    params
  });
}
// 新增订阅
export function addSubscribe(data) {
  return request({
    url: `/cbd-portal/account/profile/subscribe/policy/add`,
    method: "post",
    data
  });
}
// 新增收藏
export function addCollect(data) {
  return request({
    url: `/cbd-portal/account/profile/favorite/add`,
    method: "post",
    data
  });
}
// 取消收藏
export function deleteCollect(data) {
  return request({
    url: `/cbd-portal/account/profile/favorite/delete`,
    method: "post",
    data
  });
}
// 判断是否收藏收藏
export function checkSelectedCollect(data) {
  return request({
    url: `/cbd-portal/account/profile/favorite/checkSelected`,
    method: "post",
    data
  });
}
// 获取收藏列表
export function getCollectPage(params) {
  return request({
    url: `/cbd-portal/account/profile/favorite/policy/getPage`,
    method: "get",
    params
  });
}
// 获取订阅列表
export function getSubscribePage(params) {
  return request({
    url: `/cbd-portal/account/profile/subscribe/policy/getPage`,
    method: "get",
    params
  });
}
// 取消订阅
export function deleteSubscribe(data) {
  return request({
    url: `/cbd-portal/account/profile/subscribe/policy/delete/`+data,
    method: "post",
    data
  });
}
// 取消政策订阅
export function deletePolicySubscribe(data) {
  return request({
    url: `/cbd-portal/account/profile/subscribe/delete`,
    method: "post",
    data
  });
}
// 获取行业分类
export function getIndustryList() {
  return request({
    url: `/cbd-portal/policy/getIndustryList`,
    method: "get"
  });
}

// 获取活动收藏列表
export function getActivityGetPage(params) {
  return request({
    url: `/cbd-portal/account/profile/favorite/activity/getPage`,
    method: "get",
    params
  });
}
// 埋点
export function unload(data) {
  return request({
    url: `/cbd-portal/buried/policy/unload`,
    method: "post",
    data
  });
}
export function getPolicyTagTypeList() {
  return request({
    url: `/cbd-portal/portal/policyNew/getPolicyTagTypeList`,
    method: 'get'
  })
}
export function getMatchPolicyNew(data) {
  return request({
    url: `/cbd-portal/portal/policyNew/getMatchPolicyNew`,
    method: "post",
    data
  });
}
export function getPolicyInfo(params) {
  return  request({
    url: `/cbd-portal/portal/policyNew/getPolicyInfo`,
    method: 'get',
    params
  })
}
export function getPolicyFile(params) {
  return  request({
    url: `/cbd-portal/portal/policyNew/getPolicyFile`,
    method: 'get',
    params
  })
}
export function getPolicyTags(params) {
  return  request({
    url: `/cbd-portal/portal/policyNew/getPolicyTags`,
    method: 'get',
    params
  })
}
export function getCompanyByName(params) {
  return  request({
    url: `/cbd-portal/common/getCompanyByName`,
    method: 'get',
    params
  })
}
// 获取广州市行政区划
export function getGZAdministeration(params) {
  return  request({
    url: `/cbd-portal/policyNew/condition/getGZAdministeration`,
    method: 'get',
    params
  })
}