<template>
  <div id="appMain">
    <div class="appMain">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appMain',
  data(){
    return {

    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "./index.scss";
</style>