import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import layoutViews from "@/layoutViews/index.vue";
import { getStore } from "@/store/storage";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "index",
    component: layoutViews,
    children: [
      {
        path: "index",
        name: "首页",
        component: index,
      },
    ],
  },
  {
    path: "/test",
    component: layoutViews,
    children: [
      {
        path: "testOne",
        name: "测试页面一",
        component: () => import("@/views/testViewOne/index.vue"),
      },
      {
        path: "testTwo",
        name: "测试页面二",
        component: () => import("@/views/testViewTwo/index.vue"),
      },
    ],
  },
  {
    path: '/callback/tyrz',
    name: 'callbackTyrz',
    component: () => import('@/views/callback/tayrz/index.vue'),
    hidden: true
  },
  {
    path: '/yzy/thirdLogin',
    name: 'yzyThirdLogin',
    component: () => import('@/views/user/thirdLogin/index.vue'),
    hidden: true
  },
  {
    path: "/user",
    component: layoutViews,
    children: [
      {
        path: "login",
        name: "登录",
        component: () => import("@/views/user/login/index.vue"),
      },
      {
        path: "register",
        name: "注册",
        component: () => import("@/views/user/register/index.vue"),
      },
      {
        path: "repeatPassword",
        name: "忘记密码",
        component: () => import("@/views/user/repeatPassword/index.vue"),
      },
      {
        path: "agreement",
        name: "服务协议",
        component: () => import("@/views/user/agreement/index.vue"),
      },
    ],
  },
  {
    path: "/globalSearch",
    component: layoutViews,
    children: [
      {
        path: "index",
        name: "全局搜索",
        component: () => import("@/views/globalSearch/index.vue"),
      },
    ],
  },
  {
    path: "/policy",
    component: layoutViews,
    children: [
      {
        path: "list",
        name: "惠企政策",
        component: () => import("@/views/policy/list/index.vue"),
      },
      {
        path: "detail",
        name: "政策详情",
        component: () => import("@/views/policy/detail/index.vue"),
      }
    ],
  },
  {
    path: "/investHome",
    component: layoutViews,
    redirect: "/investHome/index",
    children: [
      {
        path: "index",
        name: "投资指引",
        component: () => import("@/views/investHome/index.vue"),
      },
      {
        path: "tabs",
        name: "投资指引详情",
        component: () => import("@/views/investHome/tabs/index"),
      },
      {
        path: "detail",
        name: "投资指引详情文案内容",
        component: () => import("@/views/investHome/tabs/components/detail"),
      },
    ],
  },
  {
    path: "/governmentEnterpriseInteraction",
    component: layoutViews,
    children: [
      {
        path: "governmentEnterpriseInteraction",
        name: "政企互动",
        component: () =>
          import("@/views/governmentEnterpriseInteraction/index.vue"),
      },
      {
        path: "governmentEnterpriseInteraction/activityDetail",
        name: "政企互动详情",
        component: () =>
          import("@/views/governmentEnterpriseInteraction/components/activityDetail.vue"),
      },
    ],
  },
  {
    path: "/corporateStyle",
    component: layoutViews,
    redirect: "/corporateStyle/index",
    children: [
      {
        path: "index",
        name: "企业风采",
        component: () => import("@/views/corporateStyle/index.vue"),
      },
      {
        path: "release",
        name: "发布风采",
        component: () =>
          import("@/views/corporateStyle/components/actionDetail/index.vue"),
      },
      {
        path: "detail",
        name: "风采详情",
        component: () =>
          import("@/views/corporateStyle/components/detail/index.vue"),
      },
      {
        path: "content",
        name: "风采内容",
        component: () =>
          import("@/views/corporateStyle/components/content/index.vue"),
      },
    ],
  },
  {
    path: "/enterpriseCenter",
    component: layoutViews,
    redirect: "/enterpriseCenter/index",
    children: [
      {
        path: "index",
        name: "企业中心",
        component: () => import("@/views/enterpriseCenter/index.vue"),
      },
      {
        path: "secondaryPages",
        name: "企业中心二级",
        component: () =>
          import("@/views/enterpriseCenter/secondaryPages/index.vue"),
      },
      {
        path: "demandDetail/:id/:type",
        name: "我的供需详情",
        component: () =>
          import("@/views/myBusiness/compnonents/myDemand/detail/index.vue"),
      },
      {
        path: "supplyDemand/:id/:type",
        name: "供需详情",
        component: () =>
          import("@/views/enterpriseCenter/supplyDemand/detail/index.vue"),
      },
      {
        path: "supplyDemandForm/:type",
        name: "供需表单",
        component: () =>
          import("@/views/enterpriseCenter/supplyDemand/index.vue"),
      },
      {
        path: "investmentIntentionForm",
        name: "投资意向",
        component: () =>
          import("@/views/enterpriseCenter/investmentIntention/index.vue"),
      },
      {
        path: "noticeCenter",
        name: "消息中心",
        component: () =>
          import("@/views/enterpriseCenter/noticeCenter/index.vue"),
      },
      {
        path: "Interested",
        name: "猜你感兴趣",
        component: () =>
          import("@/views/enterpriseCenter/Interested/index.vue"),
      },
      {
        path: "basicInformation",
        name: "基本信息",
        component: () => import("@/views/basicInformation/index.vue"),
      },
      {
        path: "myBusiness",
        name: "我的事项",
        component: () => import("@/views/myBusiness/index.vue"),
      },
      {
        path: "myCollect",
        name: "我的收藏",
        component: () => import("@/views/myCollect/index.vue"),
      },
      {
        path: "mySubscribe",
        name: "我的订阅",
        component: () => import("@/views/mySubscribe/index.vue"),
      },
      {
        path: "editInfo",
        name: "编辑信息",
        component: () =>
          import("@/views/basicInformation/compnonents/editEnterprise/index.vue"),
      },
      {
        path: "editHistory",
        name: "修改历史",
        component: () =>
          import("@/views/basicInformation/compnonents/editHistory/index.vue"),
      },
      {
        path: "editinfoDetail",
        name: "详细信息",
        component: () =>
          import("@/views/basicInformation/compnonents/editinfoDetail/index.vue"),
      },
      {
        path: "editBasicInformation",
        name: "编辑主体信息",
        component: () =>
          import("@/views/basicInformation/compnonents/editBasicInformation/index.vue"),
      },


    ],
  },
  {
    path: "/resourceMap",
    component: layoutViews,
    children: [
      {
        path: "investmentGuidelines",
        name: "资源地图",
        component: () => import("@/views/investmentGuidelines/index.vue"),
      },
    ],
  },
  {
    path: "/AIRobot",
    component: layoutViews,
    children: [
      {
        path: "AIRobot",
        name: "AI机器人",
        component: () => import("@/views/AIRobot/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (getStore("token")) {
    if (
      Object.keys(store.getters.userInfo).length &&
      Object.keys(store.getters.companyInfo).length
    ) {
      next();
    } else {
      if (to.path === '/callback/tyrz') {
        next();
      }else{
        try {
          await Promise.all([
            store.dispatch("GetInfo"),
            store.dispatch("GetCompanyInfo"),
          ]);
          next();
        } catch (error) {
          // 报错返回登陆页
        }
      }
    }
  } else {
    next();
  }
});

export default router;
