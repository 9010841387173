<template>
  <div id="layoutViews">
    <div class="layoutViews">
      <headerComponent></headerComponent>
      <appMain/>
      <template v-if="isShowFooter">
        <footerComponent></footerComponent>
      </template>
    </div>
  </div>
</template>

<script>
// 组件
import headerComponent from "@/layoutViews/headerComponent";
import appMain from "@/layoutViews/appMain";
import footerComponent from "@/layoutViews/footerComponent";

export default {
  name: 'layoutViews',
  components: {
    headerComponent,
    appMain,
    footerComponent
  },
  data(){
    return {
      overflowTip: 'visible',
      isShowFooter: false, // 是否显示底脚
    }
  },
  computed: {
    isOverflowHidden(){
      return this.$store.state.isOverflowHidden
    }
  },
  watch: {
    isOverflowHidden(val){
      this.overflowTip=val?'hidden': 'visible'
    },
    $route(val){
      if(val.path!='/index'){
        this.isShowFooter=true
      }else{
        this.isShowFooter=false
      }
    }
  },
  mounted() {
    if(this.$route.path!='/index'){
      this.isShowFooter=true
    }else{
      this.isShowFooter=false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "./index.scss";
</style>

