import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { accountGetProfile } from "@/api/user/loginRegister";
import { getAccountCompanyInfo } from "@/api/governmentEnterpriseInteraction/enterpriseAppeal";

export default new Vuex.Store({
  state: {
    name: "",
    token: "",
    headerStatus: false, // 头部显示状态
    isOverflowHidden: false, // 头部显示状态
    nickName: "", // 用户昵称
    acctCompany: null, // 相关企业信息
    avatar: "", // 头像
    isRefresh: false, // 是否重新刷新
    userInfo: {}, // 用户基本信息
    companyInfo: {}, // 用户公司基本信息
  },
  getters: {
    name: (state) => state.name,
    token: (state) => state.token,
    acctCompany: (state) => state.acctCompany,
    nickName: (state) => state.nickName,
    avatar: (state) => state.avatar,
    isRefresh: (state) => state.avatar,
    userInfo: (state) => state.userInfo,
    companyInfo: (state) => state.companyInfo,
  },
  mutations: {
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_HEADERSTATUS: (state, headerStatus) => {
      state.headerStatus = headerStatus;
    },
    SET_ISOVERFLOWHIDDEN: (state, isOverflowHidden) => {
      state.isOverflowHidden = isOverflowHidden;
    },
    SET_NICKNAME: (state, nickName) => {
      state.nickName = nickName;
    },
    SET_ACCTCOMPANY: (state, acctCompany) => {
      state.acctCompany = acctCompany;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ISREFRESH: (state, isRefresh) => {
      state.isRefresh = isRefresh;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_COMPANY_INFO: (state, companyInfo) => {
      state.companyInfo = companyInfo;
    },
  },
  actions: {
    async GetInfo({ commit }) {
      const res = await accountGetProfile();
      if (res.code === 200) {
        commit("SET_USER_INFO", res.data || {});
        return res;
      } else {
        return Promise.reject({ res });
      }
    },
    async GetCompanyInfo({ commit }) {
      const res = await getAccountCompanyInfo();
      if (res.code === 200) {
        commit("SET_COMPANY_INFO", res.data || {});
        return res;
      } else {
        return Promise.reject({ res });
      }
    },
  },
  modules: {

  },
});
