// 网关： business
import request from "@/utils/request";

// 发送验证码
export function smsVerifyCode(data) {
  return request({
    url: `/auth/account/smsVerifyCode`,
    method: "post",
    data,
  });
}

// 获取图形验证码
export function gatewayCode(params) {
  return request({
    url: `/code`,
    method: "get",
    params,
  });
}

// 注册
export function registerAccountNumber(data) {
  return request({
    url: `/auth/account/register`,
    method: "post",
    data,
  });
}
// 手机号登录
export function loginMobile(data) {
  return request({
    url: `/auth/account/login/mobile`,
    method: "post",
    data,
  });
}
// 账户密码登录
export function login(data) {
  return request({
    url: `/auth/account/login`,
    method: "post",
    data,
  });
}
// 退出登录
export function loginOut() {
  return request({
    url: `/auth/account/logout`,
    method: "post",
  });
}

// 账户密码重置
export function resetPwd(data) {
  return request({
    url: `/auth/account/resetPwd`,
    method: "post",
    data,
  });
}
// 获取省统一认证平台认证链接
export function getAuthUrl() {
  return request({
    url: `/auth/sso/gdtyrz/getAuthUrl`,
    method: "post",
  });
}
// 省统一认证平台接口登入(通过返回返回code)
export function gdtyrzLogin(data) {
  return request({
    url: `/auth/sso/gdtyrz/login`,
    method: "post",
    data,
  });
}
// 获取省统一认证平台认证链接
export function getThirdWebDumpUrl(params) {
  return request({
    url: `/auth/third/getThirdWebDumpUrl`,
    method: "get",
    params
  });
}
// 省统一认证平台接口登入(通过返回返回code)
export function thirdWebLogin(data) {
  return request({
    url: `/auth/third/thirdWebLogin`,
    method: "post",
    data,
  });
}
// 获取调用广东统一身份认证web端退出登录态连接
export function getAuthOut() {
  return request({
    url: `/auth/third/getThirdWebQuitUrl`,
    method: "get",
  });
}
// 通过手机号码检查是否关联企业(存在data，返回企业信息，否则返回null)
export function checkRelationCompanyBy(data) {
  return request({
    url: `/cbd-portal/account/checkRelationCompanyBy/${data}`,
    method: "get",
  });
}

// 获取个人信息
export function accountGetProfile() {
  return request({
    url: `/cbd-portal/account/getProfile`,
    method: "get",
  });
}
// 获取粤政议地址
export function getYZYAuthUrl(params) {
  return request({
    url: `/auth/third/getYZYAuthUrl`,
    method: "get",
    params
  });
}
// 根据code获取token
export function yzyLoginByCode(params) {
  return request({
    url: `/auth/third/yzyLoginByCode`,
    method: "get",
    params
  });
}