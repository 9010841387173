/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
}

export function createNewID() {
    const date = new Date().getTime();
    return `${date}`;
}


export function setAllKeysToNull(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            this.$set(obj, key, null);
        }
    }
}