import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// 全局点击遮罩层不关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false;
// import 'element-ui/lib/theme-chalk/index.css';
// 导入本地，后续更新ui库需要替换最新
import "@/assets/element-ui/index.css";
import { setStore, getStore, removeStore } from "./store/storage";

import "./assets/scss/element-variables.scss";

// 全局调用通用方法
import { echoLabel, goExternalPage, message } from "@/utils/generalMethod";

Vue.prototype.echoLabel = echoLabel; // 回显
Vue.prototype.goExternalPage = goExternalPage; // 跳转外部链接

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$setStore = setStore;
Vue.prototype.$getStore = getStore;
Vue.prototype.$removeStore = removeStore;
Vue.prototype.$message = message;

//配置安全密钥
window._AMapSecurityConfig = {
  // securityJsCode: "56dbad916d7ab7f8b09b267d7f79572d", //*  安全密钥-旧
  securityJsCode: "81e5be36fca0319017e2ce41fb479c98", //*  安全密钥-新
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
