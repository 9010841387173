import request from "@/utils/request";

// 获取活动列表
export function activityGetActivityPageNoAuth(params) {
    return request({
        url: `/cbd-portal/activity/getActivityPageNoAuth`,
        method: 'get',
        params: params
    })
}

// 获取企业风采
export function enterpriseDemeanourFindList(params) {
    return request({
        url: `/cbd-portal/enterprise/demeanour/findList`,
        method: 'get',
        params: params
    })
}

// 判断账号是否过期
export function validatePassDue(params) {
    return request({
        url: `/user/account/validatePassDue`,
        method: 'get',
        params: params
    })
}