import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import { setStore, getStore, removeStore } from "../store/storage";
import router from "@/router/index";
import { message } from "@/utils/generalMethod";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "/api",
  // 超时
  timeout: 30000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    config.params = {
      _random: Math.random(), // 随机数
      ...config.params,
    };
    // if (getStore('token')) {
    // config.headers['Authorization'] = 'Bearer 1-f02e2f01-8cf9-441c-bc30-56278bb216eb' // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    if (getStore("token")) {
      config.headers["Authorization"] = getStore("token"); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // TODO 修复POST请求传输数组值出现的bug
    // if(config.method === 'get' || config.method === 'post') {
    //     config.paramsSerializer = function(params) {
    //         return qs.stringify(params,{arrayFormat:'repeat'})
    //     }
    // }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    if (code !== 200 && code !== 202 && code !== 401) {
      message({
        message: res.data.msg,
        type: "error",
      });
      return Promise.reject(res.data.msg);
    } else if (code === 401) {
      message({
        message: "登录状态已失效，请重新登录",
        type: "error",
      });
      removeStore("token");
      setTimeout(() => {
        location.href = "/user/login";
      }, 800);
      // router.push({path:'/user/login'})
      return Promise.reject(new Error(res.data.msg));
    } else {
      // 含有文件名的返回流全部返回
      if (JSON.stringify(res.headers).indexOf("filename") > -1) {
        return res;
      }
      return res.data;
    }
  },
  (error) => {
    if (error == "Error: Network Error") {
      Message({
        message: "当前网络不可用",
        type: "error",
        duration: 5 * 1000,
      });
    } else {
      Message({
        message: error.message,
        type: "error",
        duration: 5 * 1000,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
