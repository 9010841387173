<template>
  <div id="headerComponent">
    <div
      class="headerComponent clear"
      :class="{ active: isActive || headerState || !isFixed }"
    >
      <div class="headerComponent-left">
        <div class="headerComponent-left-logoBox">
          <div class="logoBox-img clear">
            <img class="logoBox-img-content" src="../../assets/home/logo.png" />
            <div
              class="logoBox-img-title"
              :style="{
                color:
                  isActive || headerState || !isFixed ? '#303133' : '#FFFFFF',
              }"
            >
              天河“政企+”服务平台
            </div>
          </div>
        </div>
      </div>
      <div class="headerComponent-right clear">
        <div class="headerComponent-right-nav">
          <template v-for="(item, index) in navLists">
            <div
              class="nav-li"
              :class="{ active: item.id == nowItem }"
              @click="!item.children && toChangeNav(item)"
              @mouseover="isActive=true, item.children && toShowOrHideNavOption(1, index)"
              @mouseout="isActive=false, item.children && toShowOrHideNavOption(2, index)"
              :key="index">
              {{ item.name }}
              <div v-if="item.children && item.showNavChildrenOption" class="nav-li-children">
                <template v-for="(item2, index2) in item.children">
                  <div @click="toGoRouter(item2)" class="children-li" :key="'children'+index2">{{item2.name}}</div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div class="headerComponent-right-search">
          <i @click="$router.push('/globalSearch/index')" class="search-icon el-icon-search"></i>
        </div>
        <div v-if="!token" class="headerComponent-right-user clear" @click="toLogin()">
          <img v-if="isActive || headerState || !isFixed" class="user-icon" src="../../assets/home_20230518/home_newIcon_03.png"/>
          <img v-else class="user-icon" src="../../assets/home_20230518/home_newIcon_20.png"/>
          <div class="user-word">登录</div>
        </div>
        <div v-else class="headerComponent-right-userLoggedIn" @mouseenter="toShowOrHideLoginOption(1)" @mouseleave="toShowOrHideLoginOption(2)">
          <img class="userLoggedIn-avatar" :src="avatar?avatar:'/fileweb/avatar/mrAvatar.png'"/>
          <div v-if="showLoginOption" class="userLoggedIn-card">
            <div class="userLoggedIn-card-name">{{acctCompany?(acctCompany.companyName?acctCompany.companyName:(nickName?nickName:'游客')):(nickName?nickName:'游客') }}</div>
            <div @click="toLoginOut()" class="userLoggedIn-card-btn">退出登录</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setStore, getStore, removeStore } from '../../store/storage'
import {loginOut} from "@/api/user/loginRegister";

export default {
  name: "headerComponent",
  data() {
    return {
      navLists: [
        {
          id: "1",
          code: "index",
          path: "/index",
          name: "首页",
        },
        {
          id: "4",
          code: "policy",
          path: "/policy/list",
          name: "惠企政策",
        },
        {
          id: "3",
          code: "investHome",
          path: "/investHome/index",
          showNavChildrenOption: false,
          children: [
            {
              skipType: 1, // 跳转路由方式
              name: '投资指引',
              path: '/investHome/tabs?action=1'
            },
            {
              skipType: 2, // 新开页面跳转路由
              name: '资源地图',
              path: '/resourceMap/investmentGuidelines'
            },
            {
              skipType: 3, // 打开外部链接
              name: 'VR看地',
              path: 'https://www.720yun.com/t/fbvkc71w727?scene_id=65507162'
            },
          ],
          name: "投资指引",
        },
        {
          id: "7",
          code: "governmentEnterpriseInteraction",
          path: "/governmentEnterpriseInteraction/governmentEnterpriseInteraction",
          name: "政企互动",
        },
        {
          id: "5",
          code: "corporateStyle",
          path: "/corporateStyle/index",
          name: "企业风采",
        },
        {
          id: "6",
          code: "enterpriseCenter",
          path: "/enterpriseCenter/index",
          name: "企业中心",
        },
      ],
      nowItem: "1",
      isActive: false, // 是否选中
      isFixed: true, // 是否固定
      showLoginOption: false, //
      hideLoginOptionTimer: null, // 时间器
      hideNavChildrenOptionTimer: null, // 菜单子集时间器
    };
  },
  computed: {
    headerState() {
      return this.$store.state.headerStatus;
    },
    nickName() {
      return getStore('nickName')
    },
    acctCompany() {
      return getStore('acctCompany')?JSON.parse(getStore('acctCompany')):null
    },
    avatar() {
      return getStore('avatar')
    },
    token() {
      return getStore('token')
    },
    isRefresh(){
      return getStore('isRefresh')
    }
  },
  watch: {
    $route(val) {
      this.navLists.forEach((e) => {
        if (val.path == e.path || val.path.indexOf(e.code)!=-1) {
          this.nowItem = e.id;
          if (val.path != "/index") {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
          this.$store.commit("SET_HEADERSTATUS", false);
        }
      });
    },
  },
  methods: {
    // 菜单切换
    toChangeNav(row) {
      this.$store.commit("SET_HEADERSTATUS", false);
      this.$router.push(row.path);
      this.nowItem = row.id;
    },
    // 登录
    toLogin() {
      this.$router.push({
        path: "/user/login",
      });
    },
    // 不显示
    toShowOrHideLoginOption(type){
      var that=this
      if(type==1){
        clearTimeout(that.hideLoginOptionTimer)
        that.showLoginOption=true
      }else if(type==2){
        that.hideLoginOptionTimer=setTimeout(()=>{
          that.showLoginOption=false
        }, 500)
        that.hideLoginOptionTimer
      }
    },
    // 鼠标移入显示
    toShowOrHideNavOption(type, index){
      var that=this
      if(type==1){
        clearTimeout(that.hideNavChildrenOptionTimer)
        that.navLists[index].showNavChildrenOption=true
      }else if(type==2){
        that.hideNavChildrenOptionTimer=setTimeout(()=>{
          that.navLists[index].showNavChildrenOption=false
        }, 500)
        that.hideNavChildrenOptionTimer
      }
    },
    // 退出登录
    toLoginOut(){
      var that=this
      loginOut().then(res=>{
        if(res.code==200){
          that.$message.success('退出成功')
          removeStore('token')
          removeStore('nickName')
          removeStore('acctCompany')
          removeStore('avatar')
          setTimeout(()=>{
            that.$router.push('/index')
            that.$router.go(0)
          }, 500)
        }
      })
    },
    // 子级路由跳转
    toGoRouter(item) {
      if(item.skipType==1){
        this.$router.push({
          path: item.path,
        })
      }else if(item.skipType==2){
        let path = this.$router.resolve({
          path: item.path,
        });
        window.open(path.href, "_blank");
      }else if(item.skipType==3){
        window.open(item.path, "_blank");
      }
    },
  },
  mounted() {
    this.navLists.forEach((e) => {
      if (this.$route.path == e.path || this.$route.path.indexOf(e.code)!=-1) {
        this.nowItem = e.id;
        if (this.$route.path != "/index") {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
        this.$store.commit("SET_HEADERSTATUS", false);
      }
    });
    if (this.$route.path != "/index") {
      this.$store.commit("SET_HEADERSTATUS", true);
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "./index.scss";
</style>
