import request from '@/utils/request'

// 网关：file
// 上传附件
export function updateFile(data) {
    return request({
        url: `/file/file/uploadMultipartFile`,
        headers: { "Content-Type": "multipart/form-data" },
        method: "post",
        data: data
    });
}

// 获取id
export function approvalGetId(id) {
    return request({
        url: `/cbd-portal/component/approval/getId`,
        method: 'get',
    })
}

// 创建申请单
export function approvalCreate(data) {
    return  request({
        url: `/cbd-portal/component/approval/create`,
        method: 'post',
        data: data
    })
}

// 新增表单项
export function formDataAdd(data) {
    return  request({
        url: `/cbd-portal/component/form/data/add`,
        method: 'post',
        data: data
    })
}

// 申请记录完成
export function approvalComplete(data) {
    return  request({
        url: `/cbd-portal/component/approval/approvalComplete`,
        method: 'post',
        data: data
    })
}

// 获取流程列表
export function getListNoPage(params) {
    return  request({
        url: `/cbd-portal/component/process/getListNoPage`,
        method: 'get',
        params: params
    })
}

// 获取街道列表
export function getStreet(type) {
    return request({
        url: `/cbd-portal/investMap/getStreet`,
        method: "get",
    });
}

// 获取对应的企业信息
export function getAccountCompanyInfo() {
    return request({
        url: `/cbd-portal/account/getAccountCompanyInfo`,
        method: "get",
    });
}

// 获取所属行业
export function getIndustryList() {
    return request({
        url: `/cbd-portal/investMap/getIndustryList`,
        method: "get",
    });
}

export function getConfigKey(data) {
    return  request({
      url: `/user/system/common/getConfigKey`,
      method: 'post',
      data
    })
}
// 获取所选流程表单开始节点结构化数据
export function getFormStructure(params) {
    return  request({
        url: `/cbd-portal/services/demandNew/getFormStructure`,
        method: 'get',
        params: params
    })
}

// 诉求填报提交
export function demandNewCommit(data) {
    return  request({
        url: `/cbd-portal/services/demandNew/commit`,
        method: 'post',
        data
    })
}
