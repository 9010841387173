<template>
  <div
    id="homePage"
    :style="{ overflow: overflowTip }"
    @click="isShowRotRightSlider = false"
  >
    <div class="homePage" ref="page">
      <div
        class="homePage-body"
        ref="container"
        @mousewheel="mouseWheel"
        @DOMMouseScroll="mouseWheel"
      >
        <div class="homePage-body-item homePage-body-page1">
          <div class="page1-banner">
            <div class="page1-banner-li">
              <img
                class="li-img active"
                src="@/assets/home/天河CBD-无字版.png"
              />
            </div>
            <!-- <div class="page1-banner-li">
              <img
                class="li-img noActive"
                src="../assets/home/bannerpc02.png"
              />
            </div>
            <div class="page1-banner-li">
              <img
                class="li-img noActive"
                src="../assets/home/bannerpc03.png"
              />
            </div>
            <div class="page1-banner-li">
              <img
                class="li-img noActive"
                src="../assets/home/bannerpc04.jpg"
              />
            </div>
            <div class="page1-banner-li">
              <img
                class="li-img"
                :class="{
                  active: bannerIndex == 4,
                  noActive: bannerIndex != 4,
                }"
                src="../assets/home/bannerpc05.jpg"
              />
            </div> -->
          </div>

          <!-- <div class="page1-centerCircle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1903 937">
              <circle
                id="centerCircle1"
                class="page1-centerCircle-content"
                stroke="#ffffff"
                stroke-linecap="round"
                fill="transparent"
                stroke-width="3"
                cx="951.5"
                cy="468.5"
                r="277"
                transform="translate(951.5 468.5) rotate(-90) translate(-951.5 -468.5)"
                style="stroke-dasharray: 1741"
                stroke-dashoffset="1741"
              ></circle>
              <circle
                class="dotted-circle1"
                stroke="#ffffff"
                stroke-linecap="round"
                fill="transparent"
                stroke-width="3"
                cx="951.5"
                cy="468.5"
                r="277"
                style="stroke-dasharray: 0, 48.3611"
              ></circle>
              <circle
                class="dotted-circle2 page1-centerCircle-content3"
                :class="{ active: centerCircle3Status }"
                stroke="#ffffff"
                stroke-linecap="round"
                fill="transparent"
                stroke-width="3"
                cx="951.5"
                cy="468.5"
                r="277"
                transform-origin="951.5px 468.5px"
                transform="rotate(-90)"
                style="stroke-dasharray: 0, 48.3611"
              ></circle>
              <g
                id="cursor"
                class="page1-centerCircle-cursor"
                transform-origin="951.5px 468.5px"
                :style="{ transform: cursorTransform }"
              >
                <circle
                  class="cursor-circle"
                  :class="{ active: centerCircle3Status }"
                  stroke="#ffffff"
                  fill="transparent"
                  transform-origin="951.5px 191.5px"
                  stroke-width="3"
                  r="10"
                  cx="951.5"
                  cy="191.5"
                ></circle>
              </g>
            </svg>
          </div> -->

          <!-- <div class="page1-bannerMenu">
            <template v-for="(bannerMenuItem, bannerMenuIndex) in bannerMenu">
              <div
                @click="toChangeBanner(bannerMenuIndex)"
                class="page1-bannerMenu-li clear"
                :class="{ active: bannerIndex == bannerMenuIndex }"
                :key="'bannerMenu' + bannerMenuIndex"
              >
                <svg
                  class="li-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <circle
                    class="li-svg-circle"
                    stroke-linecap="round"
                    width="30"
                    height="30"
                    cx="15"
                    cy="15"
                    r="2"
                    fill="transparent"
                  />
                  <g class="li-svg-wrapper">
                    <circle
                      class="wrapper-background"
                      stroke-linecap="round"
                      width="30"
                      height="30"
                      cx="15"
                      cy="15"
                      stroke-width="2"
                      stroke-opacity="0.3"
                      r="12"
                      fill="transparent"
                    />
                    <circle
                      class="wrapper-foreground"
                      stroke-linecap="round"
                      width="30"
                      height="30"
                      cx="15"
                      cy="15"
                      stroke-width="2"
                      r="12"
                      fill="transparent"
                    />
                  </g>
                </svg>
                <div class="li-title">{{ bannerMenuItem }}</div>
              </div>
            </template>
          </div> -->

          <div class="page1-belowPart">
            <img
              class="page1-belowPart-img1"
              src="../assets/home_20230518/home_newIcon_04.png"
            />
            <img
              class="page1-belowPart-img2"
              src="../assets/home_20230518/home_newIcon_11.png"
            />
          </div>
        </div>
        <div class="homePage-body-item homePage-body-page3">
          <div
            class="page3-box"
            :class="{ active: itemIndex == 2, noActive: itemIndex != 2 }"
          >
            <div class="page3-box-title">
              汇<span style="color: #ffa019">天河</span>筑未来
            </div>
            <div class="page3-box-content">
              如今，广州市天河区是粤港澳大湾区首屈一指的总部中心、金融中心、科技中心，高端服务中心和国际交往中心，是广州参与粤港澳大湾区建设和全球竞争的主力军。是全球企业的投资首选地和最佳发展地。
            </div>
            <div class="page3-box-videoPeriphery">
              <div
                @click="toSwitchVideoBox('left')"
                class="videoPeriphery-leftBtn"
              >
                <i class="btnIcon el-icon-arrow-left"></i>
              </div>
              <div class="page3-box-videoBoxs">
                <div
                  :style="{ transform: videoBoxTransform }"
                  ref="page3_box_videoBox"
                  class="page3-box-videoBox"
                >
                  <video
                    ref="video1"
                    class="videoBox-content"
                    src="/fileweb/enterprise/home/tianheCBDVideo.mp4"
                    autoplay
                    muted
                    loop
                  ></video>
                  <video
                    ref="video2"
                    class="videoBox-content"
                    src="/fileweb/enterprise/home/tianheCBDvideo_02.mp4"
                    autoplay
                    muted
                    loop
                  ></video>
                </div>
                <div class="videoBox-mask" @click="toPlayVideo()">
                  <i class="videoBox-mask-icon el-icon-video-play"></i>
                </div>
              </div>
              <div
                @click="toSwitchVideoBox('right')"
                class="videoPeriphery-rightBtn"
              >
                <i class="btnIcon el-icon-arrow-right"></i>
              </div>
              <div class="videoPeriphery-schedule">
                <div
                  :class="{ active: videoBoxDot == 1 }"
                  class="videoPeriphery-schedule-dot"
                ></div>
                <div
                  :class="{ active: videoBoxDot == 2 }"
                  class="videoPeriphery-schedule-dot"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="homePage-body-item homePage-body-page2">
          <div
            class="page2-box"
            :class="{ active: itemIndex == 3, noActive: itemIndex != 3 }"
          >
            <div class="page2-box-body clear">
              <div class="body-leftPart">
                <div class="body-leftPart-title1">重点平台</div>
                <template v-if="chooseTerrace == 1">
                  <div class="body-leftPart-title2">天河中央商务区</div>
                  <div class="body-leftPart-content">
                    天河中央商务区位于广州新城市中轴线与珠江黄金岸线的交汇点，占地面积20平方公里
                    ，包括已建成的12平方公里天河北及珠江新城，以及正在开发建设中的8平方公里的广州国际金融城，形成“梯次开发”的科学规划布局。天河中央商务区是广州城市功能的主要承载区....
                    <div
                      @click="
                        toGoRouterMap('/resourceMap/investmentGuidelines')
                      "
                      class="content-btn"
                    >
                      <div class="content-btn-tip">详细</div>
                      <i class="content-btn-icon el-icon-right"></i>
                    </div>
                  </div>
                </template>

                <template v-if="chooseTerrace == 2">
                  <div class="body-leftPart-title2">广州国际金融城</div>
                  <div class="body-leftPart-content">
                    总面积8平方公里的广州国际金融城，西至华南快线，东至天河区界，北至黄埔大道、中山大道，南至珠江，分起步区、东区、西区、北区4个片区，聚焦建设国际化综合金融中心、数字经济融合创新引领区、珠江滨水活力典范区，全力打造产业耦合共生、产城融合共促的智慧城、生态城和理想城......
                    <div
                      @click="
                        toGoRouterMap('/resourceMap/investmentGuidelines')
                      "
                      class="content-btn"
                    >
                      <div class="content-btn-tip">详细</div>
                      <i class="content-btn-icon el-icon-right"></i>
                    </div>
                  </div>
                </template>

                <template v-if="chooseTerrace == 3">
                  <div class="body-leftPart-title2">天河智慧城</div>
                  <div class="body-leftPart-content">
                    天河智慧城位于天河区东北部，南接天河中央商务区,东临广州科学城,北为帽峰山森林公园，总面积约78平方公里，核心区规划面积20.69平方公里。2020年2月25日，天河智慧城被认定为省级高新技术产业开发区，定名为广州天河高新技术产业开发区......
                    <div
                      @click="
                        toGoRouterMap('/resourceMap/investmentGuidelines')
                      "
                      class="content-btn"
                    >
                      <div class="content-btn-tip">详细</div>
                      <i class="content-btn-icon el-icon-right"></i>
                    </div>
                  </div>
                </template>

                <template v-if="chooseTerrace == 4">
                  <div class="body-leftPart-title2">天河智谷</div>
                  <div class="body-leftPart-content">
                    天河智谷片区位于我区东部，西起科韵路、东至珠吉路、北至北环高速与沈海高速公路、南至广园快速路，面积约15.2平方公里。天河智谷片区城市空间地位重要，是联结融汇国际金融城、天河智慧城、广州科学城，打造广深科技创新走廊的重要节点。天河智谷作为天河高新区重要组成部分......
                    <div
                      @click="
                        toGoRouterMap('/resourceMap/investmentGuidelines')
                      "
                      class="content-btn"
                    >
                      <div class="content-btn-tip">详细</div>
                      <i class="content-btn-icon el-icon-right"></i>
                    </div>
                  </div>
                </template>
                <div
                  @click="toGoRouterMap('/resourceMap/investmentGuidelines')"
                  class="body-leftPart-btn"
                >
                  More
                </div>
              </div>
              <div class="body-rightPart">
                <div class="body-rightPart-topLi">
                  <div class="topLi-li topLi-li1">
                    <div
                      class="topLi-li-body topLi-li1-body"
                      @mouseenter="chooseTerrace = 1"
                    >
                      <img
                        class="body-img"
                        src="../assets/home/courtship01.png"
                      />
                      <div class="body-title">天河中央商务区</div>
                    </div>
                  </div>
                  <div class="topLi-li topLi-li2">
                    <div
                      class="topLi-li-body topLi-li2-body"
                      @mouseenter="chooseTerrace = 2"
                    >
                      <img
                        class="body-img"
                        src="../assets/home/courtship02.png"
                      />
                      <div class="body-title">广州国际金融城</div>
                    </div>
                  </div>
                </div>
                <div class="body-rightPart-belowLi">
                  <div class="belowLi-li belowLi-li1">
                    <div
                      class="belowLi-li-body belowLi-li1-body"
                      @mouseenter="chooseTerrace = 3"
                    >
                      <img
                        class="body-img"
                        src="../assets/home/courtship03.png"
                      />
                      <div class="body-title">天河智慧城</div>
                    </div>
                  </div>
                  <div class="belowLi-li belowLi-li2">
                    <div
                      class="belowLi-li-body belowLi-li2-body"
                      @mouseenter="chooseTerrace = 4"
                    >
                      <img
                        class="body-img"
                        src="../assets/home_20230518/home_newIcon_13.png"
                      />
                      <div class="body-title">天河智谷</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homePage-body-item homePage-body-page4">
          <div
            class="page4-box"
            :class="{ active: itemIndex == 4, noActive: itemIndex != 4 }"
          >
            <div class="page4-box-title">惠企政策</div>
            <div class="page4-box-newBody clear">
              <div class="newBody-leftPart">
                <div class="newBody-leftPart-img">
                  <img
                    v-if="page4LeftActive == 1"
                    src="../assets/home_20230518/home_newIcon_07.png"
                  />
                  <img
                    v-else-if="page4LeftActive == 2"
                    src="../assets/home_20230518/home_newIcon_02.png"
                  />
                  <img
                    v-else-if="page4LeftActive == 3"
                    src="../assets/home_20230518/home_newIcon_10.png"
                  />
                  <div class="img-title">
                    {{
                      page4LeftActive == 1
                        ? "创业创新"
                        : page4LeftActive == 2
                        ? "产业扶持"
                        : "人才建设"
                    }}
                  </div>
                </div>
                <div class="newBody-leftPart-list">
                  <div
                    @mouseenter="page4LeftActive = 1"
                    class="list-li"
                    :class="{ active: page4LeftActive == 1 }"
                  >
                    创业创新
                  </div>
                  <div
                    @mouseenter="page4LeftActive = 2"
                    class="list-li"
                    :class="{ active: page4LeftActive == 2 }"
                  >
                    产业扶持
                  </div>
                  <div
                    @mouseenter="page4LeftActive = 3"
                    class="list-li"
                    :class="{ active: page4LeftActive == 3 }"
                  >
                    人才建设
                  </div>
                  <div
                    class="list-li"
                    @click="toGoRouter('/policy/list?type=3')"
                  >
                    更多政策 >
                  </div>
                </div>
              </div>
              <div class="newBody-rightPart">
                <div class="newBody-rightPart-list">
                  <div
                    class="list-li clear"
                    style="cursor: pointer"
                    @click="$router.push(`/policy/list?id=${item.id}&type=3`)"
                    v-for="(item, index) in policyListData"
                    :key="index"
                  >
                    <div class="list-li-title">{{ item.title }}</div>
                    <div class="list-li-time">{{ item.dispatchTime }}</div>
                  </div>
                </div>
                <div
                  class="newBody-rightPart-btn"
                  @click="
                    toGoRouter(
                      '/policy/list?type=3&policyTopics=' +
                        (page4LeftActive == 1
                          ? '1'
                          : page4LeftActive == 2
                          ? '2'
                          : '4')
                    )
                  "
                >
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homePage-body-item homePage-body-page5">
          <div
            class="page5-box"
            :class="{ active: itemIndex == 5, noActive: itemIndex != 5 }"
          >
            <div class="page5-box-title">投资指引</div>
            <div class="page5-box-body">
              <img class="body-img" src="../assets/home/page5_04.png" />
              <div class="body-content">
                <div class="body-content-li clear">
                  <img class="li-icon1" src="../assets/home/page5_03.png" />
                  <div class="li-word">资源地图</div>
                  <img
                    class="li-icon2"
                    src="../assets/home/page5_05.png"
                    @click="toGoRouterMap('/resourceMap/investmentGuidelines')"
                  />
                </div>
                <div class="body-content-li clear">
                  <img class="li-icon1" src="../assets/home/page5_06.png" />
                  <div class="li-word">土地推介全景VR</div>
                  <img
                    class="li-icon2"
                    src="../assets/home/page5_05.png"
                    @click="
                      toGoExternalPage(
                        'https://www.720yun.com/t/fbvkc71w727?scene_id=65507162'
                      )
                    "
                  />
                </div>
                <div class="body-content-li clear">
                  <img class="li-icon1" src="../assets/home/page5_01.png" />
                  <div class="li-word">产业概况</div>
                  <img
                    class="li-icon2"
                    src="../assets/home/page5_05.png"
                    @click="toGoRouter('/investHome/tabs?action=1')"
                  />
                </div>
                <div class="body-content-li clear">
                  <img class="li-icon1" src="../assets/home/page5_02.png" />
                  <div class="li-word">发展规划</div>
                  <img
                    class="li-icon2"
                    src="../assets/home/page5_05.png"
                    @click="toGoRouter('/investHome/tabs?action=1')"
                  />
                </div>
                <div
                  class="body-content-btn"
                  @click="toGoRouter('/investHome/index')"
                >
                  查看更多
                </div>
              </div>
            </div>

            <!--            <div class="page5-box-content clear">-->
            <!--              <div class="content-left">-->
            <!--                <template v-if="pointActive==1">-->
            <!--                  <div class="content-left-title1">资源地图</div>-->
            <!--                  <div class="content-left-title2">战略平台·产业园区·重点楼宇·重点企业</div>-->
            <!--                  <div class="content-left-word">基于精准定位技术，通过地图将重点平台、产业园区、楼宇坐标位置精准显示，并结合产业、区域可查询到相关资源信息，带您领略广州市天河区各类资源风采</div>-->
            <!--                  <div class="content-left-btn" @click="toGoRouter('/investHome/tabs?action=3')">More</div>-->
            <!--                </template>-->
            <!--                <template v-else-if="pointActive==2">-->
            <!--                  <div class="content-left-title1">产业概况</div>-->
            <!--                  <div class="content-left-word">聚焦“强链、延链、补链”，瞄准龙头企业、链主企业、“专精特新”企业现代服务业和战略性新兴产业“双轮驱动”,坚持产业第一，更好服务“制造业当家”大战略,既要更好发挥金融业、新一代信息技术、现代商贸业、高端专业服务业、房地产业等支柱产业关键支撑作用,又要全力加快发展现代都市工业、生物医药与健康产业等战略性新兴产业</div>-->
            <!--                  <div class="content-left-btn" @click="toGoRouter('/investHome/tabs?action=4')">More</div>-->
            <!--                </template>-->
            <!--              </div>-->
            <!--              <div class="content-right">-->
            <!--                <div class="content-right-background">-->
            <!--                  <img v-if="pointActive==1" class="background-img" src="../assets/home/map03.png"/>-->
            <!--                  <img v-else-if="pointActive==2" class="background-img" src="../assets/home/map01.png"/>-->
            <!--                </div>-->
            <!--                <div class="content-right-operate clear">-->
            <!--                  <div class="operate-left" :class="{'active': pointActive==1}" @mouseenter="pointActive=1" >-->
            <!--                    <div class="operate-left-body">-->
            <!--                      <div class="body-dots">-->
            <!--                        <div class="body-dots-small"></div>-->
            <!--                      </div>-->
            <!--                      <div class="body-title">资源地图</div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="operate-right" :class="{'active': pointActive==2}" @mouseenter="pointActive=2">-->
            <!--                    <div class="operate-right-body">-->
            <!--                      <div class="body-dots">-->
            <!--                        <div class="body-dots-small"></div>-->
            <!--                      </div>-->
            <!--                      <div class="body-title">产业概况</div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="homePage-body-item homePage-body-page6">
          <div
            class="page6-box"
            :class="{ active: itemIndex == 6, noActive: itemIndex != 6 }"
          >
            <div class="page6-box-title">政企互动</div>
            <div class="page6-box-content clear">
              <div class="content-li content-li3">
                <div class="content-li-title">
                  <span class="title-span">招商线索</span>
                </div>
                <div class="content-li-body">
                  <div class="body-title">政府部门及时接收，政企高效联动</div>
                  <!--                  <div class="body-time">2023年2月28日</div>-->
                  <img
                    class="body-img"
                    src="../assets/home_20230518/home_newIcon_01.png"
                  />
                </div>
                <!--                <div class="content-li-body">-->
                <!--                  <div class="body-typeBtn">-->
                <!--                    <div class="body-typeBtn-top">-->
                <!--                      <img class="top-icon" src="../assets/home/interaction03.png">-->
                <!--                    </div>-->
                <!--                    <div class="body-typeBtn-title">求助</div>-->
                <!--                  </div>-->
                <!--                  <div class="body-typeBtn">-->
                <!--                    <div class="body-typeBtn-top">-->
                <!--                      <img class="top-icon" src="../assets/home/interaction04.png">-->
                <!--                    </div>-->
                <!--                    <div class="body-typeBtn-title">意见</div>-->
                <!--                  </div>-->
                <!--                  <div class="body-typeBtn">-->
                <!--                    <div class="body-typeBtn-top">-->
                <!--                      <img class="top-icon" src="../assets/home/interaction05.png">-->
                <!--                    </div>-->
                <!--                    <div class="body-typeBtn-title">投诉</div>-->
                <!--                  </div>-->
                <!--                  <div class="body-typeBtn">-->
                <!--                    <div class="body-typeBtn-top">-->
                <!--                      <img class="top-icon" src="../assets/home/interaction06.png">-->
                <!--                    </div>-->
                <!--                    <div class="body-typeBtn-title">举报</div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div
                  @click="
                    toGoRouter('/enterpriseCenter/investmentIntentionForm')
                  "
                  class="content-li-btn"
                >
                  去填报
                </div>
              </div>

              <div class="content-li content-li2">
                <div class="content-li-title">
                  <span class="title-span">宣讲视频</span>
                </div>
                <div class="content-li-body">
                  <div class="body-title">企业政策宣讲视频</div>
                  <!--                  <div class="body-time">2023年2月28日</div>-->
                  <img
                    class="body-img"
                    src="../assets/home/interaction01.png"
                  />
                </div>
                <div
                  @click="
                    toGoRouter(
                      '/governmentEnterpriseInteraction/governmentEnterpriseInteraction?menuValue=2'
                    )
                  "
                  class="content-li-btn"
                >
                  More
                </div>
              </div>

              <div class="content-li content-li1">
                <div class="content-li-title">
                  <span class="title-span">最新活动</span>
                </div>
                <div class="content-li-body">
                  <template v-for="(item, index) in activitylistData">
                    <div
                      v-if="index <= 3"
                      class="body-li"
                      :key="'activitylistData_' + index"
                    >
                      <div class="body-li-word">{{ item.title }}</div>
                      <div
                        @click="
                          toActivityDetail(
                            item.id,
                            item.type,
                            item.activityType
                          )
                        "
                        class="body-li-btn"
                      >
                        <div class="btn-tip">参与</div>
                        <i class="btn-icon el-icon-right"></i>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  @click="
                    toGoRouter(
                      '/governmentEnterpriseInteraction/governmentEnterpriseInteraction?menuValue=3'
                    )
                  "
                  class="content-li-btn"
                >
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="homePage-body-item homePage-body-page7">
          <div
            class="page7-box"
            :class="{
              active: itemIndex == 7 || itemIndex == 8,
              noActive: itemIndex != 7 && itemIndex != 8,
            }"
          >
            <div class="page7-box-title">企业风采</div>
            <div
              class="page7-box-banner clear"
              :style="{ transform: transformWidth }"
            >
              <template v-for="(item, index) in corporateStyleListData">
                <div class="banner-li" :key="'corporateStyleListData' + index">
                  <div class="banner-li-imgBox">
                    <img
                      class="imgBox-content"
                      :src="
                        item.imgs && item.imgs.length
                          ? item.imgs[0] ||
                            require('@/assets/corporateStyle/normal.png')
                          : require('@/assets/corporateStyle/normal.png')
                      "
                    />
                  </div>
                  <div class="banner-li-titleBox">
                    <div class="titleBox-word">{{ item.companyName }}</div>
                    <div
                      @click="
                        toGoRouter('/corporateStyle/detail?id=' + item.id)
                      "
                      class="titleBox-btn"
                    >
                      <div class="titleBox-btn-tip">详情</div>
                      <i class="titleBox-btn-icon el-icon-right"></i>
                    </div>
                  </div>
                  <!--                  <div class="banner-li-tip">{{item.tip}}</div>-->
                </div>
              </template>
            </div>
            <div id="progress" class="page7-box-progress">
              <div
                class="progress-leftBtn"
                @click="toChangKeyEnterprises('left')"
              >
                <i class="progress-leftBtn-icon el-icon-arrow-left"></i>
              </div>
              <div
                :style="{ width: this.progressLineWidth }"
                class="progress-line"
              ></div>
              <div
                class="progress-rightBtn"
                @click="toChangKeyEnterprises('right')"
              >
                <i class="progress-rightBtn-icon el-icon-arrow-right"></i>
              </div>
            </div>
            <div
              @click="toGoRouter('/corporateStyle/index')"
              class="page7-box-btn"
            >
              More
            </div>
          </div>
        </div>
        <!--        <div class="homePage-body-item homePage-body-page8">-->
        <!--          <div class="page8-box" :class="{'active': itemIndex==8, 'noActive': itemIndex!=8}">-->
        <!--            <div class="page8-box-title">数字服务出口基地</div>-->
        <!--            <div class="page8-box-body clear">-->
        <!--              <div class="body-li body-li1">互联网仲裁平台</div>-->
        <!--              <div class="body-li body-li2">供销社</div>-->
        <!--              <div class="body-li body-li3">企业出海营销平台</div>-->
        <!--              <div class="body-li body-li4">出海法律服务</div>-->
        <!--              <div class="body-li body-li5">出海撮合平台</div>-->
        <!--            </div>-->
        <!--            <div class="page8-box-btn">前往基地</div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div ref="footerComponent" class="homePage-body-page8">
          <footerComponent />
        </div>
      </div>
    </div>
    <div v-if="showVideo" class="video-popup">
      <div class="video-popup-videoBox">
        <video
          v-if="videoBoxDot == 1"
          ref="video2"
          class="videoBox-video"
          src="/fileweb/enterprise/home/tianheCBDVideo.mp4"
          autoplay
          muted
          controls
          loop
        ></video>
        <video
          v-else-if="videoBoxDot == 2"
          ref="video2"
          class="videoBox-video"
          src="/fileweb/enterprise/home/tianheCBDvideo_02.mp4"
          autoplay
          muted
          controls
          loop
        ></video>
        <div class="videoBox-icon" @click="handleCloseVideo()">
          <i class="el-icon-close videoBox-icon-content"></i>
        </div>
      </div>
    </div>
    <div @click.stop="isShowRotRightSlider = true" class="robotSuspension">
      <img class="robotSuspension-img" src="../assets/home/aiRobot_02.png" />
      <!--      <div class="robotSuspension-title">智慧企服</div>-->
    </div>
    <div class="robotSuspension_rightSlider" v-show="isShowRotRightSlider">
      <img src="../assets/home/rot_bgc.png" alt="" class="rot_bgc" />
      <div class="ai_avatar_con" @click="toGoRouter('/AIRobot/AIRobot')">
        <img src="../assets/home/ai_avatar.png" alt="" class="ai_avatar" />
        <div>AI小智</div>
      </div>
      <div
        class="tianhe_rot_con"
        @click="toGoExternalPage('http://www.thnet.gov.cn/znzx.html')"
      >
        <img src="../assets/home/tianhe_rot.png" alt="" class="tianhe_rot" />
        <div>天河政务</div>
        <div>智能机器人</div>
      </div>
    </div>

    <div
      style="
        position: fixed;
        left: 1.042vw;
        bottom: 7vw;
        width: 8.854vw;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      "
      @click="handlerPage"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          cursor: pointer;
        "
      >
        穗好办@企
      </div>
      <img
        src="../assets/home/pic_zcT.1b87de19.png"
        style="width: 100%; position: absolute; left: 0; top: 0; z-index: -1"
      />
    </div>
    <!--    新：2023-5-18 -->
    <div v-if="itemIndex != 1" class="broadside">
      <template v-for="(item, index) in broadsideList">
        <div
          @click="toSwitchPage(item.id)"
          class="broadside-li"
          :class="{
            active: broadsideActive == item.id,
          }"
          :key="'broadsideList' + index"
          @mouseenter="broadsideActive = item.id"
          @mouseleave="broadsideActive = ''"
        >
          <img
            v-if="broadsideActive == item.id"
            class="broadside-li-iconBox"
            :src="item.activeIcon"
          />
          <img v-else class="broadside-li-iconBox" :src="item.icon" />
          <div class="broadside-li-label">{{ item.label }}</div>
        </div>
      </template>
    </div>
    <!-- 招商线索浮窗 -->
    <div class="investment_clues_box">
      <router-link
        to="/enterpriseCenter/investmentIntentionForm?entranceType=1"
      >
        <img src="@/assets/home/investment_icon.png" />
      </router-link>
    </div>
  </div>
</template>
<script>
// 组件
import footerComponent from "@/layoutViews/footerComponent";
import { gdtyrzLogin } from "@/api/user/loginRegister";

// api
import {
  activityGetActivityPageNoAuth,
  enterpriseDemeanourFindList,
  validatePassDue,
} from "@/api/home";
import { getMatchPolicyNew } from "@/api/policy/index";

export default {
  name: "homePage",
  components: {
    footerComponent,
  },
  data() {
    return {
      isShowRotRightSlider: false,
      bannerTimer: null, // 轮播图计时器
      bannerIndex: 0,
      bannerMenu: [
        "天河CBD",
        "天河智慧城",
        "华南第一商圈",
        "高质量发展",
        "广州国际金融城",
      ],
      chooseTerrace: 1, // 平台选择
      pointActive: 1, // 投资指引选择
      keyEnterprisesList: [
        {
          id: 1,
          name: "中国电信股份有限公司(广州天河区分公司)",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业1.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业1.png",
        },
        {
          id: 2,
          name: "中人集团",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业2.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业2.png",
        },
        {
          id: 3,
          name: "三七互娱",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业3.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业3.png",
        },
        {
          id: 4,
          name: "凤凰公司",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业4.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业4.png",
        },
        {
          id: 5,
          name: "网易",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业5.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业5.png",
        },
        {
          id: 6,
          name: "广州五羊建设机械公司",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业6.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业6.png",
        },
        {
          id: 7,
          name: "广州市净水有限公司",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业7.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业7.png",
        },
        {
          id: 8,
          name: "广州风行牛奶有限公司",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业8.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业8.png",
        },
        {
          id: 9,
          name: "广州天河区肖璐商贸有限公司",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业9.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业9.png",
        },
        {
          id: 10,
          name: "广州鼎国生物技术有限公司",
          tip: "四上企业、高新技术企业",
          src:
            window.location.hostname != "localhost"
              ? window.location.origin + "/fileweb/enterprise/home/企业10.png"
              : "https://cbddev.easemob.top/fileweb/enterprise/home/企业10.png",
        },
      ],
      isScroll: false, // 是否能滚动
      itemIndex: 0, // 当前下标
      len: 8, // 总个数
      cursorTransform: "",
      centerCircle3Status: false, //

      keyEnterprisesIndex: 2,
      transformWidth: "",
      progressLineWidth: "",

      overflowTip: "hidden",
      showVideo: false,

      activitylistData: [], // 活动数据
      corporateStyleListData: [], // 企业风采
      policyListData: [], //惠企政策

      videoBoxDot: 1, //
      videoBoxTransform: "none",
      broadsideList: [
        {
          id: 3,
          activeIcon: require("@/assets/home_20230518/home_newIcon_18.png"),
          icon: require("@/assets/home_20230518/home_newIcon_19.png"),
          label: "重点平台",
        },
        {
          id: 4,
          activeIcon: require("@/assets/home_20230518/home_newIcon_05.png"),
          icon: require("@/assets/home_20230518/home_newIcon_06.png"),
          label: "惠企政策",
        },
        {
          id: 5,
          activeIcon: require("@/assets/home_20230518/home_newIcon_14.png"),
          icon: require("@/assets/home_20230518/home_newIcon_15.png"),
          label: "投资指引",
        },
        {
          id: 6,
          activeIcon: require("@/assets/home_20230518/home_newIcon_16.png"),
          icon: require("@/assets/home_20230518/home_newIcon_17.png"),
          label: "政企互动",
        },
        {
          id: 7,
          activeIcon: require("@/assets/home_20230518/home_newIcon_08.png"),
          icon: require("@/assets/home_20230518/home_newIcon_09.png"),
          label: "企业风采",
        },
        {
          id: 1,
          activeIcon: require("@/assets/home_20230518/home_newIcon_22.png"),
          icon: require("@/assets/home_20230518/home_newIcon_21.png"),
          label: "回到顶部",
        },
      ],
      broadsideActive: "",
      page4LeftActive: "1",
    };
  },
  watch: {
    page4LeftActive(val) {
      var queryObj = {
        pageNum: 1,
        pageSize: 5,
      };
      if (val == 1) {
        queryObj.policyTheme = "ZC2110010002";
      } else if (val == 2) {
        queryObj.policyTheme = "ZC2110010012";
      } else if (val == 3) {
        queryObj.policyTheme = "ZC2110010007";
      }
      getMatchPolicyNew(queryObj).then((res) => {
        this.policyListData = res.rows;
      });
    },
  },
  beforeCreate() {
    //获取url中的参数token，保存localStorage中
    console.log(this.$route.query.code);
    if (this.$route.query.code) {
      gdtyrzLogin({ code: this.$route.query.code })
        .then((res) => {
          this.$setStore("token", res.data.token);
          this.$router.push({
            path: "/",
          });
        })
        .catch((err) => {
          this.$router.push({
            path: "/",
          });
        });
    }
  },
  mounted() {
    console.log("index");
    // 初始加一，为了加载第一页动画
    setTimeout(() => (this.itemIndex += 1), 10);
    this.initQueryData();
    this.initData();
    // validatePassDue().then(res=>{
    //   if(!res.data){
    //     this.$confirm('密码长时间未更换，建议更换密码?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {

    //     })
    //   }
    // })
  },
  destroyed() {
    clearInterval(this.bannerTimer);
  },
  methods: {
    handlerPage() {
      window.open("https://qyfw.gzonline.gov.cn/qyfw/home");
    },
    //鼠标移入
    handlerMouseover() {
      this.isShowRotRightSlider = true;
    },
    //鼠标移出
    handlerMouseout() {
      this.isShowRotRightSlider = false;
    },
    // 初始化操作
    initData() {
      var that = this;
      that.bannerTimer = setInterval(() => {
        if (that.bannerIndex == 4) {
          that.bannerIndex = 0;
        } else {
          that.bannerIndex = that.bannerIndex + 1;
        }
        var centerCircle1 = document.getElementById("centerCircle1");
        var circd = centerCircle1.getTotalLength();
        var zongj = circd / 5;
        var zongc = zongj * that.bannerIndex;
        centerCircle1.getAttributeNode("stroke-dashoffset").value = parseInt(
          that.bannerIndex == 0
            ? circd
            : circd - zongc + (that.bannerIndex == 4 ? 0 : 15)
        );
        var du = (360 / 5) * that.bannerIndex;
        that.cursorTransform = "rotate(" + du + "deg)";
        that.centerCircle3Status = true;
        setTimeout(() => {
          that.centerCircle3Status = false;
        }, 1000);
      }, 5000);

      // var pageWidth=window.innerWidth,
      //     itemWidth=document.getElementsByClassName('banner-li')[0].offsetWidth+30,
      //     progressWidth=document.getElementById('progress').offsetWidth
      // this.transformWidth='translateX('+ (pageWidth/2-itemWidth/2-itemWidth*this.keyEnterprisesIndex) +'px)'
      // this.progressLineWidth=(progressWidth/this.keyEnterprisesList.length*(this.keyEnterprisesIndex+1))+'px'
    },
    // 初始化数据
    async initQueryData() {
      var that = this;
      let res = await getMatchPolicyNew({
        pageNum: 1,
        pageSize: 5,
        policyTheme: "ZC2110010002",
      });
      that.policyListData = res.rows;
      await activityGetActivityPageNoAuth({ pageNum: 1, pageSize: 10 }).then(
        (res) => {
          if (res.code == 200) {
            that.activitylistData = res.rows;
          }
        }
      );
      await enterpriseDemeanourFindList({ pageNum: 1, pageSize: 10 }).then(
        (res) => {
          if (res.code == 200) {
            that.corporateStyleListData = res.rows;
            if (that.corporateStyleListData.length > 3) {
              that.keyEnterprisesIndex = 2;
            } else {
              that.keyEnterprisesIndex = 1;
            }
            var pageWidth = window.innerWidth,
              itemWidth =
                document.getElementsByClassName("banner-li")[0].offsetWidth +
                30,
              progressWidth = document.getElementById("progress").offsetWidth;
            that.transformWidth =
              "translateX(" +
              (pageWidth / 2 -
                itemWidth / 2 -
                itemWidth * that.keyEnterprisesIndex) +
              "px)";
            that.progressLineWidth =
              (progressWidth / that.corporateStyleListData.length) *
                (that.keyEnterprisesIndex + 1) +
              "px";
          }
        }
      );
    },
    // 切换轮播图
    toChangeBanner(index) {
      var that = this;
      clearInterval(this.bannerTimer);
      that.bannerIndex = index;
      var centerCircle1 = document.getElementById("centerCircle1");
      var circd = centerCircle1.getTotalLength();
      var zongj = circd / 5;
      var zongc = zongj * that.bannerIndex;
      centerCircle1.getAttributeNode("stroke-dashoffset").value = parseInt(
        that.bannerIndex == 0
          ? circd
          : circd - zongc + (that.bannerIndex == 4 ? 0 : 15)
      );
      var du = (360 / 5) * that.bannerIndex;
      that.cursorTransform = "rotate(" + du + "deg)";
      that.centerCircle3Status = true;
      setTimeout(() => {
        that.centerCircle3Status = false;
      }, 1000);
      that.bannerTimer = setInterval(() => {
        if (that.bannerIndex == 4) {
          that.bannerIndex = 0;
        } else {
          that.bannerIndex = that.bannerIndex + 1;
        }
        var centerCircle1 = document.getElementById("centerCircle1");
        var circd = centerCircle1.getTotalLength();
        var zongj = circd / 5;
        var zongc = zongj * that.bannerIndex;
        console.log(centerCircle1.getTotalLength());
        centerCircle1.getAttributeNode("stroke-dashoffset").value = parseInt(
          that.bannerIndex == 0
            ? circd
            : circd - zongc + (that.bannerIndex == 4 ? 0 : 15)
        );
        var du = (360 / 5) * that.bannerIndex;
        that.cursorTransform = "rotate(" + du + "deg)";
        that.centerCircle3Status = true;
        setTimeout(() => {
          that.centerCircle3Status = false;
        }, 1000);
      }, 5000);
    },
    // 滑动页面
    handleMove() {
      this.isScroll = true;
      const scrollHeight = this.$refs.page.clientHeight;
      const footerComponentHeight = this.$refs.footerComponent.clientHeight;
      console.log("this.itemIndex", this.itemIndex);
      if (this.itemIndex == 8) {
        this.$refs.container.style.transform = `translateY(-${
          (this.itemIndex - 2) * scrollHeight + footerComponentHeight
        }px)`;
      } else {
        this.$refs.container.style.transform = `translateY(-${
          (this.itemIndex - 1) * scrollHeight
        }px)`;
      }
      if (this.itemIndex == 1) {
        this.$store.commit("SET_HEADERSTATUS", false);
      } else {
        this.$store.commit("SET_HEADERSTATUS", true);
      }
      const body = window.document.getElementsByTagName("body")[0];
      if (this.itemIndex != this.len) {
        this.overflowTip = "hidden";
      } else {
        this.overflowTip = "visible";
      }
      setTimeout(() => (this.isScroll = false), 1300); // 防止连续滚动，加延时
    },
    mouseWheel(event) {
      if (this.isScroll) {
        // 防止连续滚动
        return false;
      }
      if (event.deltaY > 0) {
        if (this.itemIndex === this.len) return; // 最后一个禁止向下滚动
        this.itemIndex += 1;
      } else {
        if (this.itemIndex === 1) return; // 第一个禁止向上滚动
        this.itemIndex -= 1;
      }
      this.handleMove();
    },
    // 重点企业切换
    toChangKeyEnterprises(type) {
      // type: left--左，right--右
      var pageWidth = window.innerWidth,
        itemWidth =
          document.getElementsByClassName("banner-li")[0].offsetWidth + 30,
        progressWidth = document.getElementById("progress").offsetWidth;
      if (type == "left") {
        if (this.keyEnterprisesIndex != 0) {
          this.keyEnterprisesIndex = this.keyEnterprisesIndex - 1;
        }
      } else if (type == "right") {
        if (
          this.keyEnterprisesIndex !=
          this.corporateStyleListData.length - 1
        ) {
          this.keyEnterprisesIndex = this.keyEnterprisesIndex + 1;
        }
      }
      this.progressLineWidth =
        (progressWidth / this.corporateStyleListData.length) *
          (this.keyEnterprisesIndex + 1) +
        "px";
      this.transformWidth =
        "translateX(" +
        (pageWidth / 2 - itemWidth / 2 - itemWidth * this.keyEnterprisesIndex) +
        "px)";
    },
    // 播放cbd视频
    toPlayVideo() {
      this.showVideo = true;
      this.$refs.video1.muted = false;
      this.$refs.video1.pause();
      this.$refs.video2.muted = false;
      this.$refs.video2.play();
    },
    handleCloseVideo() {
      this.showVideo = false;
      this.$refs.video1.muted = false;
      this.$refs.video1.play();
      this.$refs.video2.muted = false;
      this.$refs.video2.pause();
    },
    // 路由跳转
    toGoRouter(path) {
      this.$router.push({
        path: path,
      });
    },
    // 资源地图跳转
    toGoRouterMap(url) {
      let path = this.$router.resolve({
        path: url,
      });
      window.open(path.href, "_blank");
    },
    // 链接跳转
    toGoExternalPage(url) {
      window.open(url, "_blank");
    },
    // 跳转活动详情
    toActivityDetail(id, type, activityType) {
      this.$router.push({
        path: "/governmentEnterpriseInteraction/governmentEnterpriseInteraction",
        query: {
          menuValue: 3,
          id: id,
          type: type,
          activityType: activityType,
        },
      });
    },
    // 新: 2023-5-18
    // 首页page切换
    toSwitchPage(id) {
      this.itemIndex = id;
      this.handleMove();
    },
    // 切换中间视频
    toSwitchVideoBox(type) {
      var itemWidth =
        document.getElementsByClassName("videoBox-content")[0].offsetWidth;
      if (type == "left") {
        if (this.videoBoxDot != 1) {
          if (this.videoBoxDot == 2) {
            this.videoBoxDot = this.videoBoxDot - 1;
            this.videoBoxTransform = "none";
          }
        }
      } else if (type == "right") {
        if (this.videoBoxDot != 2) {
          if (this.videoBoxDot == 1) {
            this.videoBoxDot = this.videoBoxDot + 1;
            this.videoBoxTransform = "translateX( -" + itemWidth + "px)";
          }
        }
      }
    },
  },
};
</script>

<style rel="stylesheet" lang="scss" scoped>
@import "./index.scss";
</style>
